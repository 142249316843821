import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { setLoginDetails } from "../actions/Actions";
import NavBar from "./NavBar";
import Header from "./Header";

const Home = (props) => {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date());
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);

  return (
    <>
      <div id="wrapper">
        <Header />
        <NavBar />
        <div id="page-wrapper">
          <div className="header">
            <h1 className="page-header">Dashboard</h1>
          </div>

          <div id="page-inner">
            <div className="row">
              <div className="col-md-12">
                <div className="panel panel-default">
                  <div className="panel-heading"></div>

                  <div className="panel-body">
                    <div className="col-md-12">
                      <div className="modal-body">
                        <div>
                          <h3 className="col-xs-6 col-md-3">
                            Current Time: {date.toLocaleTimeString()}
                          </h3>
                          <h3 className="col-xs-6 col-md-3">
                            Current Date: {date.toLocaleDateString()}
                          </h3>

                          <h3 className="col-xs-6 col-md-3">
                            Signed In As: {currentUser.FullName}
                          </h3>
                          <div className="panel-heading"></div>
                          <div className="panel-heading"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*{currentUser.Permission === 1 && (*/}
            {/*  <>*/}
            {/*    <div className="row">*/}
            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa-money fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(dailyGeneralSales)}*/}
            {/*            </h3>*/}
            {/*            <strong> General Daily Sales</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa-shopping-cart fa-5x"></i>*/}
            {/*          </div>*/}

            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(dailyCafeSales)}*/}
            {/*            </h3>*/}
            {/*            <strong> Daily Cafe Sales</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa-shopping-cart fa-5x"></i>*/}
            {/*          </div>*/}

            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(dailyLaundrySales)}*/}
            {/*            </h3>*/}
            {/*            <strong> Daily Laundry Sales</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa fa-comments fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(monthlyGeneralSales)}*/}
            {/*            </h3>*/}
            {/*            <strong>*/}
            {/*              {" "}*/}
            {/*              {monthNames[currentDate.getMonth()]} General Sales*/}
            {/*            </strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa fa-comments fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(monthlyCafeSales)}*/}
            {/*            </h3>*/}
            {/*            <strong>*/}
            {/*              {" "}*/}
            {/*              Cafe {monthNames[currentDate.getMonth()]} Sales*/}
            {/*            </strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa fa-comments fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(monthlyLaundrySales)}*/}
            {/*            </h3>*/}
            {/*            <strong>*/}
            {/*              {" "}*/}
            {/*              Laundry {monthNames[currentDate.getMonth()]} Sales*/}
            {/*            </strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa fa-comments fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(currentYearSales)}*/}
            {/*            </h3>*/}
            {/*            <strong> {currentYear} Sales</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="col-md-3 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-primary text-center no-boder blue">*/}
            {/*          <div className="panel-left pull-left blue">*/}
            {/*            <i className="fa fa fa-comments fa-5x"></i>*/}
            {/*          </div>*/}
            {/*          <div className="panel-right">*/}
            {/*            <h3*/}
            {/*              style={{*/}
            {/*                fontSize: "24px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {currencyConverter(allYearsSales)}*/}
            {/*            </h3>*/}
            {/*            <strong> Total Sales</strong>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}

            {/*    <div className="row">*/}
            {/*      <div className="col-md-12"></div>*/}
            {/*    </div>*/}

            {/*    <div className="row">*/}
            {/*      <div className="col-md-4 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-default">*/}
            {/*          <div className="panel-heading">Jobs on Hold</div>*/}
            {/*          <div className="panel-body">*/}
            {/*            <div className="list-group">*/}
            {/*              <a href="#" className="list-group-item">*/}
            {/*                <span className="badge">7 minutes ago</span>*/}
            {/*                <i className="fa fa-fw fa-comment"></i> Commented on*/}
            {/*                a post*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*            <div className="text-right">*/}
            {/*              <a href="#">*/}
            {/*                More Tasks{" "}*/}
            {/*                <i className="fa fa-arrow-circle-right"></i>*/}
            {/*              </a>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className="col-md-8 col-sm-12 col-xs-12">*/}
            {/*        <div className="panel panel-default">*/}
            {/*          <div className="panel-heading">Today's Sales Entry</div>*/}
            {/*          <div className="panel-body">*/}
            {/*            <div className="table-responsive">*/}
            {/*              <table className="table table-striped table-bordered table-hover">*/}
            {/*                <thead>*/}
            {/*                  <tr>*/}
            {/*                    <th>S No.</th>*/}
            {/*                    <th>First Name</th>*/}
            {/*                    <th>Last Name</th>*/}
            {/*                    <th>User Name</th>*/}
            {/*                    <th>Email ID.</th>*/}
            {/*                  </tr>*/}
            {/*                </thead>*/}
            {/*                <tbody>*/}
            {/*                  <tr>*/}
            {/*                    <td>1</td>*/}
            {/*                    <td>John</td>*/}
            {/*                    <td>Doe</td>*/}
            {/*                    <td>John15482</td>*/}
            {/*                    <td>name@site.com</td>*/}
            {/*                  </tr>*/}
            {/*                </tbody>*/}
            {/*              </table>*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </>*/}
            {/*)}*/}

            <footer>
              <p>
                All right reserved. Designed by:{" "}
                <a href="#">Enyimba Tv & Radio</a>
              </p>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
