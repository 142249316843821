import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  encryptData,
  formatDate,
  formatDateAndTime,
  serverLink,
} from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import arrayShuffle from "array-shuffle";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Table from "../pages/table/table";
import Header from "./Header";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "./SubmittingLoader/SubmittingLoader";

function AddOAP(props) {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [station] = useState(
    typeof props?.HomePageData.stations !== "undefined"
      ? props?.HomePageData.stations
      : []
  );

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? props?.HomePageData.oap
      : []
  );

  const [createItem, setCreateItem] = useState({
    OAPId: "",
    FullName: "",
    EmailAddress: "",
    PhoneNumber: "",
    Password: "",
    IsActive: "",
    StationId: "",
    Description: "",
    Passport: "",
    Biography: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      OAPId: "",
      FullName: "",
      EmailAddress: "",
      PhoneNumber: "",
      Password: "",
      IsActive: "",
      StationId: "",
      Description: "",
      Passport: "",
      Biography: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Full Name",
        field: "FullName",
      },
      {
        label: "Phone Number",
        field: "PhoneNumber",
      },
      {
        label: "Station",
        field: "StationId",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigate = useNavigate();

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "cover") {
        setSelectedFile(e.target.files[0]);
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getStaff = async () => {
    await axios
      .get(`${serverLink}shared/oap/list`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            const stationName = station.filter(
              (x) => x.StationId === staff.StationId
            )[0];

            rows.push({
              sn: index + 1,
              OAPId: staff.OAPId,
              FullName: staff.FullName,
              EmailAddress: staff.EmailAddress,
              PhoneNumber: staff.PhoneNumber,
              Password: staff.Password,
              IsActive: staff.IsActive,
              StationId: stationName.StationName,
              Description: staff.Description,
              Passport: staff.Passport,
              Biography: staff.Biography,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#a7613b" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      OAPId: staff.OAPId,
                      FullName: staff.FullName,
                      EmailAddress: staff.EmailAddress,
                      PhoneNumber: staff.PhoneNumber,
                      Password: staff.Password,
                      IsActive: staff.IsActive,
                      StationId: staff.StationId,
                      Description: staff.Description,
                      Passport: staff.Passport,
                      Biography: staff.Biography,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getStaff().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        console.log("!!!!!", response.data.file.url);
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const onSubmitProgram = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "OAPId" &&
        key !== "Passport" &&
        key !== "Password"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (createItem.Passport && createItem.Passport.size / 1024 > 2048) {
      await showAlert("", `Image Size Can't be more than 2MB`, "error");
      setIsSubmittingForm(false);
      return false;
    }

    const formDataCover = new FormData();
    formDataCover.append("cover", selectedFile);

    const cover =
      selectedFile &&
      selectedFile.size > 0 &&
      (await uploadImage(formDataCover, "oap/upload/oap/cover"));

    const sendData = {
      OAPId: createItem.OAPId,
      FullName: createItem.FullName,
      EmailAddress: createItem.EmailAddress,
      PhoneNumber: createItem.PhoneNumber,
      Password: encryptData(createItem.Password),
      IsActive: createItem.IsActive,
      StationId: createItem.StationId,
      Description: createItem.Description,
      Passport: cover,
      Biography: createItem.Biography,
    };

    if (createItem.OAPId === "") {
      await axios
        .post(`${serverLink}oap/add`, sendData)
        .then(async (res) => {
          if (res.data.message === "success") {
            await showAlert("Success", `OAP Added Successfully`, "success");
            resetItem();
            await getStaff();
            setIsSubmittingForm(false);
          }
          if (res.data.message === "exist") {
            await showAlert(
              "Error",
              `OAP with given details already exist!`,
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }

    if (createItem.OAPId !== "") {
      await axios
        .patch(`${serverLink}oap/update`, sendData)
        .then((result) => {
          if (result.data.message === "success") {
            showAlert("Success", "OAP Updated Successfully", "success");
            resetItem();
            getStaff();
            setIsSubmittingForm(false);
          } else {
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          showAlert(
            "NETWORK ERROR",
            "Please check your connection and try again!",
            "error"
          );
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage OAP</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#a7613b",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add OAP
                        </button>
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  OAP Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="FullName"
                                      type="text"
                                      placeholder="Full Name"
                                      value={createItem.FullName}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StationId"
                                      name="StationId"
                                      onChange={onEdit}
                                      value={createItem.StationId}
                                    >
                                      <option value="">Select Station</option>
                                      {station &&
                                        Object.keys(station).length > 0 &&
                                        station.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.StationId}
                                            >
                                              {item.StationName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="EmailAddress"
                                      type="text"
                                      placeholder="Email Address"
                                      value={createItem.EmailAddress}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="PhoneNumber"
                                      type="text"
                                      placeholder="Phone Number"
                                      value={createItem.PhoneNumber}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Password"
                                      type="password"
                                      placeholder="Password"
                                      value={createItem.Password}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Description"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Description"
                                      value={createItem.Description}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="Biography"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Biography"
                                      value={createItem.Biography}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="cover">Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="cover"
                                      name="cover"
                                      placeholder="OAP Passport"
                                      onChange={onEdit}
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getStaff}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#a7613b" }}
                                  className="btn btn-primary"
                                  onClick={onSubmitProgram}
                                >
                                  Save changes
                                </button>
                                {isSubmittingForm && <SubmittingLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">OAP List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOAP);
