import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  encryptData,
  formatDate,
  formatDateAndTime,
  serverLink,
} from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import arrayShuffle from "array-shuffle";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Table from "../pages/table/table";
import moment from "moment";
import Header from "./Header";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "./SubmittingLoader/SubmittingLoader";

function AddElection(props) {
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [station] = useState(
    typeof props?.HomePageData.stations !== "undefined"
      ? props?.HomePageData.stations
      : []
  );

  const [createItem, setCreateItem] = useState({
    ElectionId: "",
    ElectionTitle: "",
    Description: "",
    StationId: "",
    IsActive: "",
    StartDate: "",
    Logo: "",
    EndDate: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      ElectionId: "",
      ElectionTitle: "",
      Description: "",
      StationId: "",
      IsActive: "",
      StartDate: "",
      Logo: "",
      EndDate: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Election Title",
        field: "ElectionTitle",
      },
      {
        label: "Description",
        field: "Description",
      },
      {
        label: "Station",
        field: "StationId",
      },
      {
        label: "End Date",
        field: "EndDate",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const currentYear = new Date().getFullYear();

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "Email") {
      const result = isValidEmail(value);

      if (result === null) {
        setError("invalid");
      } else {
        setError("valid");
      }
    }

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  const getStaff = async () => {
    await axios
      .get(`${serverLink}shared/get/election`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              ElectionId: staff.ElectionId,
              ElectionTitle: staff.ElectionTitle,
              Description: staff.Description,
              StationId: staff.StationId,
              IsActive: staff.IsActive,
              StartDate: formatDateAndTime(staff.StartDate, "date") ?? "N/A",
              Logo: staff.Logo,
              EndDate: formatDateAndTime(staff.EndDate, "date") ?? "N/A",
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#a7613b" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      ElectionId: staff.ElectionId,
                      ElectionTitle: staff.ElectionTitle,
                      Description: staff.Description,
                      StationId: staff.StationId,
                      IsActive: staff.IsActive,
                      StartDate:
                        formatDateAndTime(staff.StartDate, "date") ?? "N/A",
                      Logo: staff.Logo,
                      EndDate:
                        formatDateAndTime(staff.EndDate, "date") ?? "N/A",
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(currentUser.StaffID);
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  useEffect(() => {
    getStaff().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();

    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "ElectionId" &&
        key !== "Logo"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      setIsSubmittingForm(false);
      return false;
    }

    if (createItem) {
      const sendData = {
        ElectionId: createItem.ElectionId,
        ElectionTitle: createItem.ElectionTitle,
        Description: createItem.Description,
        StationId: createItem.StationId,
        IsActive: createItem.IsActive,
        StartDate: createItem.StartDate,
        Logo: createItem.Logo,
        EndDate: createItem.EndDate,
      };

      if (createItem.ElectionId === "") {
        axios
          .post(`${serverLink}election/add`, sendData)
          .then(async (res) => {
            if (res.data.message === "success") {
              resetItem();
              await getStaff();
              await showAlert(
                "Success",
                `Election Added Successfully`,
                "success"
              );
              setIsSubmittingForm(false);
            }
            if (res.data.message === "exist") {
              await showAlert(
                "Error",
                `Election with given details already exist!`,
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }

      if (createItem.ElectionId !== "") {
        await axios
          .patch(`${serverLink}election/update`, sendData)
          .then((result) => {
            if (result.data.message === "success") {
              resetItem();
              getStaff();
              showAlert("Success", "Election Updated Successfully", "success");
              setIsSubmittingForm(false);
            } else {
              showAlert(
                "ERROR",
                "Something went wrong. Please try again!",
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            showAlert(
              "NETWORK ERROR",
              "Please check your connection and try again!",
              "error"
            );
            setIsSubmittingForm(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Election</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#a7613b",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Election
                        </button>
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Election Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="ElectionTitle"
                                      type="text"
                                      placeholder="Election Title"
                                      value={createItem.ElectionTitle}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <label>Start Date</label>
                                    <input
                                      type="date"
                                      id="StartDate"
                                      className="form-control"
                                      placeholder="Start Date*"
                                      required
                                      // max={`${currentYear - 13}-01-01`}
                                      value={formatDate(
                                        createItem.StartDate,
                                        "date"
                                      )}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <label>End Date</label>
                                    <input
                                      type="date"
                                      id="EndDate"
                                      className="form-control"
                                      placeholder="End Date*"
                                      required
                                      // max={`${currentYear - 13}-01-01`}
                                      value={formatDate(
                                        createItem.EndDate,
                                        "date"
                                      )}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StationId"
                                      name="StationId"
                                      onChange={onEdit}
                                      value={createItem.StationId}
                                    >
                                      <option value="">Select Station</option>
                                      {station &&
                                        Object.keys(station).length > 0 &&
                                        station.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.StationId}
                                            >
                                              {item.StationName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mb-4">
                                <div className="form-group">
                                  <textarea
                                    className="form-control"
                                    id="Description"
                                    type="text"
                                    style={{
                                      height: "100px",
                                    }}
                                    placeholder="Description"
                                    value={createItem.Description}
                                    onChange={onEdit}
                                  ></textarea>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getStaff}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#a7613b" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                                {isSubmittingForm && <SubmittingLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Election List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddElection);
