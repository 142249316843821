import "./App.css";
import Routing from "./pages/Routing";
import { setHomePageContents, setLoginDetails } from "./actions/Actions";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import axios from "axios";
import { serverLink } from "./resources/Url";
import { BrowserRouter as Router } from "react-router-dom";
import HomeContentLoader from "./pages/HomeContentLoader/HomeContentLoader";

function App(props) {
  const [isLoading, setIsLoading] = useState(true);

  const getHomeReport = async () => {
    await axios
      .get(`${serverLink}shared/contents`)
      .then((result) => {
        props.setOnHomePageContents(result.data);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    getHomeReport().then((r) => {});
  }, []);

  return (
    <div>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <Router>
          <Routing />
        </Router>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // loginData: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnHomePageContents: (p) => {
      dispatch(setHomePageContents(p));
    },
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
