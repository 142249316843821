import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Table from "../pages/table/table";
import Header from "./Header";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "./SubmittingLoader/SubmittingLoader";

function AddProgram(props) {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];
  const [position, setPosition] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [station] = useState(
    typeof props?.HomePageData.stations !== "undefined"
      ? props?.HomePageData.stations
      : []
  );

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? props?.HomePageData.oap
      : []
  );

  const [createItem, setCreateItem] = useState({
    ProgramId: "",
    ProgramName: "",
    StartTime: "",
    EndTime: "",
    cover: "",
    Day: "",
    OAPId: "",
    StationId: "",
    ShortDescription: "",
    LongDescription: "",
    IsActive: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      ProgramId: "",
      ProgramName: "",
      StartTime: "",
      EndTime: "",
      cover: "",
      Day: "",
      OAPId: "",
      StationId: "",
      ShortDescription: "",
      LongDescription: "",
      IsActive: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Program Name",
        field: "ProgramName",
      },
      {
        label: "Start Time",
        field: "StartTime",
      },
      {
        label: "End Time",
        field: "EndTime",
      },
      {
        label: "StationId",
        field: "StationId",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigate = useNavigate();

  const [filteredOAP, setFilteredOAP] = useState([]);
  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "StationId") {
      const selectedStationObject = station.find(
        (item) => item.StationId === Number(value)
      );

      if (selectedStationObject) {
        const filteredOAPs = oap.filter(
          (item) => item.StationId === Number(selectedStationObject.StationId)
        );
        setCreateItem({
          ...createItem,
          [id]: value,
          OAPId: "", // Reset the StaffId to empty when the station is changed
        });
        setFilteredOAP(filteredOAPs);
      }
    }
    if (e.target.type === "file") {
      if (id === "image1") {
        setSelectedFile(e.target.files[0]);
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getStaff = async () => {
    await axios
      .get(`${serverLink}shared/program/list`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            const stationName = station.find(
              (x) => x.StationId === staff.StationId
            );
            rows.push({
              sn: index + 1,
              ProgramId: staff.ProgramId,
              ProgramName: staff.ProgramName,
              StartTime: staff.StartTime,
              EndTime: staff.EndTime,
              Cover: staff.Cover,
              Day: staff.Day,
              OAPId: staff.OAPId,
              StationId: stationName ? stationName.StationName : "",
              ShortDescription: staff.ShortDescription,
              LongDescription: staff.LongDescription,
              IsActive: staff.IsActive,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#a7613b" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      ProgramId: staff.ProgramId,
                      ProgramName: staff.ProgramName,
                      StartTime: staff.StartTime,
                      EndTime: staff.EndTime,
                      Cover: staff.Cover,
                      Day: staff.Day,
                      OAPId: staff.OAPId,
                      StationId: stationName ? stationName.StationName : "",
                      ShortDescription: staff.ShortDescription,
                      LongDescription: staff.LongDescription,
                      IsActive: staff.IsActive,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  useEffect(() => {
    getStaff().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };
  const onSubmitProgram = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "ProgramId" &&
        key !== "cover" &&
        key !== "ShortDescription" &&
        key !== "LongDescription"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }

      const image1 = new FormData();
      image1.append("image1", selectedFile);

      const image =
        selectedFile &&
        selectedFile.size > 0 &&
        (await uploadImage(image1, "program/upload/program/cover"));

      const sendData = {
        ProgramId: createItem.ProgramId,
        ProgramName: createItem.ProgramName,
        StartTime: createItem.StartTime,
        EndTime: createItem.EndTime,
        Day: createItem.Day,
        OAPId: createItem.OAPId,
        StationId: createItem.StationId,
        Cover: image,
        ShortDescription: createItem.ShortDescription,
        LongDescription: createItem.LongDescription,
        IsActive: createItem.IsActive,
      };

      if (sendData && Object.keys(sendData).length > 0) {
        if (createItem.ProgramId === "") {
          axios
            .post(`${serverLink}program/add`, sendData)
            .then(async (res) => {
              if (res.data.message === "success") {
                await showAlert(
                  "Success",
                  `Program Added Successfully`,
                  "success"
                );
                setIsSubmittingForm(false);
                resetItem();
                await getStaff();
              }
              if (res.data.message === "exist") {
                await showAlert(
                  "Error",
                  `Program with given details already exist!`,
                  "error"
                );
                setIsSubmittingForm(false);
              }
            })
            .catch((error) => {
              console.log("Error", error);
            });
        }
        if (createItem.ProgramId !== "") {
          await axios
            .patch(`${serverLink}program/update`, sendData)
            .then((result) => {
              if (result.data.message === "success") {
                showAlert("Success", "Program Updated Successfully", "success");
                resetItem();
                getStaff();
                setIsSubmittingForm(false);
              } else {
                showAlert(
                  "ERROR",
                  "Something went wrong. Please try again!",
                  "error"
                );
                setIsSubmittingForm(false);
              }
            })
            .catch((error) => {
              showAlert(
                "NETWORK ERROR",
                "Please check your connection and try again!",
                "error"
              );
              setIsSubmittingForm(false);
            });
        }
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Program</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#a7613b",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Program
                        </button>
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Program Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="ProgramName"
                                      type="text"
                                      placeholder="Program Name"
                                      value={createItem.ProgramName}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="StartTime"
                                      type="text"
                                      placeholder="Start Time"
                                      value={createItem.StartTime}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="EndTime"
                                      type="text"
                                      placeholder="End Time"
                                      value={createItem.EndTime}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Day"
                                      type="text"
                                      placeholder="Day"
                                      value={createItem.Day}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StationId"
                                      name="StationId"
                                      onChange={onEdit}
                                      value={createItem.StationId}
                                    >
                                      <option value="">Select Station</option>
                                      {station &&
                                        Object.keys(station).length > 0 &&
                                        station.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.StationId}
                                            >
                                              {item.StationName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="OAPId"
                                      name="OAPId"
                                      onChange={onEdit}
                                      value={createItem.OAPId}
                                    >
                                      <option value="">Select OAP</option>
                                      {filteredOAP &&
                                        Object.keys(filteredOAP).length > 0 &&
                                        filteredOAP.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.OAPId}
                                            >
                                              {item.FullName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ShortDescription"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Short Description"
                                      value={createItem.ShortDescription}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="LongDescription"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Long Description"
                                      value={createItem.LongDescription}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="image1">Cover Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="image1"
                                      name="image1"
                                      placeholder="Cover Image"
                                      onChange={onEdit}
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getStaff}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#a7613b" }}
                                  className="btn btn-primary"
                                  onClick={onSubmitProgram}
                                >
                                  Save changes
                                </button>
                                {isSubmittingForm && <SubmittingLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Program List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProgram);
