import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  encryptData,
  formatDate,
  formatDateAndTime,
  serverLink,
} from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import arrayShuffle from "array-shuffle";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Table from "../pages/table/table";
import Header from "./Header";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "./SubmittingLoader/SubmittingLoader";

function AddStaff(props) {
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];
  const [position, setPosition] = useState(0);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [createItem, setCreateItem] = useState({
    StaffID: "",
    FullName: "",
    DOB: "",
    Salary: "",
    Email: "",
    Password: "",
    Allowance: "",
    Gender: "",
    IsActive: "",
    Permission: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      StaffID: "",
      FullName: "",
      DOB: "",
      Salary: "",
      Email: "",
      Password: "",
      Allowance: "",
      Gender: "",
      IsActive: "",
      Permission: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "StaffID",
        field: "StaffID",
      },
      {
        label: "Full Name",
        field: "FullName",
      },
      {
        label: "DOB",
        field: "DOB",
      },
      {
        label: "Salary",
        field: "Salary",
      },
      {
        label: "Allowance",
        field: "Allowance",
      },
      {
        label: "Gender",
        field: "Gender",
      },
      {
        label: "Email",
        field: "Email",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const currentYear = new Date().getFullYear();

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (id === "Email") {
      const result = isValidEmail(value);

      if (result === null) {
        setError("invalid");
      } else {
        setError("valid");
      }
    }

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  const getStaff = async () => {
    await axios
      .get(`${serverLink}shared/get/staff`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              StaffID: staff.StaffID,
              DOB: formatDateAndTime(staff.DOB, "date") ?? "N/A",
              Salary: staff.Salary,
              Email: staff.Email,
              Password: staff.Password,
              Permission: staff.Permission,
              FullName: staff.FullName,
              Allowance: staff.Allowance,
              Gender: staff.Gender,
              IsActive: staff.IsActive,
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#a7613b" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      Salary: staff.Salary,
                      Allowance: staff.Allowance,
                      Email: staff.Email,
                      StaffID: staff.StaffID,
                      Permission: staff.Permission,
                      IsActive: staff.IsActive,
                      DOB: formatDateAndTime(staff.DOB, "date") ?? "N/A",
                      FullName: staff.FullName,
                      Password: staff.Password,
                      Gender: staff.Gender,
                    })
                  }
                >
                  Edit
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(currentUser.StaffID);
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();

  useEffect(() => {
    getStaff().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (createItem.hasOwnProperty(key) && key !== "StaffID") {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (error === "invalid") {
      await showAlert("Input Error", "Invalid Email Address", "error");
      return false;
    }

    if (createItem) {
      const sendData = {
        FullName: createItem.FullName.trim(),
        StaffID: createItem.StaffID,
        DOB: createItem.DOB.trim(),
        Salary: createItem.Salary,
        Permission: createItem.Permission,
        Email: createItem.Email.trim(),
        Allowance: createItem.Allowance,
        Gender: createItem.Gender.trim(),
        IsActive: createItem.IsActive,
        Password: encryptData(createItem.Password.trim()),
      };

      if (createItem.StaffID === "") {
        axios
          .post(`${serverLink}staff/add`, sendData)
          .then(async (res) => {
            if (res.data.message === "success") {
              resetItem();
              await getStaff();
              await showAlert("Success", `Staff Added Successfully`, "success");
              setIsSubmittingForm(false);
            }
            if (res.data.message === "exist") {
              await showAlert(
                "Error",
                `Staff with given details already exist!`,
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } else {
        await axios
          .patch(`${serverLink}staff/update`, sendData)
          .then((result) => {
            if (result.data.message === "success") {
              resetItem();
              getStaff();
              showAlert("Success", "Staff Updated Successfully", "success");
              setIsSubmittingForm(false);
            } else {
              showAlert(
                "ERROR",
                "Something went wrong. Please try again!",
                "error"
              );
              setIsSubmittingForm(false);
            }
          })
          .catch((error) => {
            showAlert(
              "NETWORK ERROR",
              "Please check your connection and try again!",
              "error"
            );
            setIsSubmittingForm(false);
          });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Staff</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#a7613b",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add Staff
                        </button>
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Staff Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="FullName"
                                      type="text"
                                      placeholder="Full Name"
                                      value={createItem.FullName}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <input
                                      type="date"
                                      id="DOB"
                                      className="form-control"
                                      placeholder="Date of Birth*"
                                      required
                                      max={`${currentYear - 13}-01-01`}
                                      value={formatDate(createItem.DOB)}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Salary"
                                      type="number"
                                      placeholder="Salary"
                                      value={createItem.Salary}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Allowance"
                                      type="number"
                                      placeholder="Allowance"
                                      value={createItem.Allowance}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Email"
                                      type="email"
                                      placeholder="Email"
                                      value={createItem.Email}
                                      onChange={onEdit}
                                    />
                                    {error === "invalid" && (
                                      <span
                                        style={{
                                          backgroundColor: "red",
                                          color: "white",
                                          fontSize: "12px",
                                          padding: "5px",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {error && (
                                          <>
                                            {error === "invalid" &&
                                              "Email is not valid"}
                                          </>
                                        )}
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Password"
                                      type="password"
                                      placeholder="Password"
                                      value={createItem.Password}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 ">
                                  <div className="form-group">
                                    <select
                                      id="Gender"
                                      className="form-control"
                                      value={createItem.Gender}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Gender</option>
                                      <option value="Male">Male</option>
                                      <option value="Female">Female</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 ">
                                  <div className="form-group">
                                    <select
                                      id="Permission"
                                      className="form-control"
                                      value={createItem.Permission}
                                      onChange={onEdit}
                                    >
                                      <option value="">
                                        Select Permission
                                      </option>
                                      <option value="1">Administrator</option>
                                      <option value="2">Manager</option>
                                      <option value="3">Sales</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getStaff}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#a7613b" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Save changes
                                </button>
                                {isSubmittingForm && <SubmittingLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">Staff List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddStaff);
