import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { serverLink } from "../../resources/Url";
import { showAlert } from "../../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../../actions/Actions";
import { connect } from "react-redux";
import NavBar from "../NavBar";
import Footer from "../Footer";
import Table from "../../pages/table/table";
import Header from "../Header";
import HomeContentLoader from "../HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "../SubmittingLoader/SubmittingLoader";
import ReactMarkdown from "react-markdown";

function Applicants(props) {
  const [selectedResume, setSelectedResume] = useState(null);
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);
  const [status, setStatus] = useState("");
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();
  const [showViewer, setShowViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const content =
    "https://res.cloudinary.com/nalda-cloud/image/upload/v1703151402/lu8voojumsm1q4lhprli.pdf";

  const [createItem, setCreateItem] = useState({
    ApplicationId: "",
    FullName: "",
    EmailAddress: "",
    YearsOfExperience: "",
    PhoneNumber: "",
    Address: "",
    HighestQualification: "",
    FormerEmployee: "",
    PositionApplying: "",
    Gender: "",
    Resume: "",
    ApplicantLocation: "",
    IsSelected: "",
    CreatedDate: "",
    // #
    Message: "",
    Date: "",
    IsReply: "",
    ReplyMessage: "",
    ReplyDate: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      ApplicationId: "",
      FullName: "",
      EmailAddress: "",
      YearsOfExperience: "",
      PhoneNumber: "",
      Address: "",
      HighestQualification: "",
      FormerEmployee: "",
      PositionApplying: "",
      Gender: "",
      Resume: "",
      ApplicantLocation: "",
      IsSelected: "",
      CreatedDate: "",
      // #
      Message: "",
      Date: "",
      IsReply: "",
      ReplyMessage: "",
      ReplyDate: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Full Name",
        field: "FullName",
      },
      {
        label: "Position Applying",
        field: "PositionApplying",
      },
      {
        label: "Highest Qualification",
        field: "HighestQualification",
      },
      {
        label: "Former Employee",
        field: "FormerEmployee",
      },
      {
        label: "Phone Number",
        field: "PhoneNumber",
      },
      {
        label: "View Resume",
        field: "viewCV",
      },
      {
        label: "View More",
        field: "action",
      },
    ],
    rows: [],
  });

  const onEdit = async (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setCreateItem({
      ...createItem,
      [id]: value,
    });
  };

  const getProjects = async () => {
    await axios
      .get(`${serverLink}shared/get/applicants`)
      .then((result) => {
        if (result.data.length > 0) {
          let rows = [];
          result.data.map((staff, index) => {
            rows.push({
              sn: index + 1,
              ApplicationId: staff.ApplicationId,
              FullName: staff.FullName,
              EmailAddress: staff.EmailAddress,
              YearsOfExperience: staff.YearsOfExperience,
              PhoneNumber: staff.PhoneNumber,
              Address: staff.Address,
              HighestQualification: staff.HighestQualification,
              FormerEmployee: staff.FormerEmployee,
              PositionApplying: staff.PositionApplying,
              Gender: staff.Gender,
              Resume: staff.Resume,
              ApplicantLocation: staff.ApplicantLocation,
              IsSelected: staff.IsSelected,
              CreatedDate: staff.CreatedDate,
              // #
              Message: staff.Message,
              Date: staff.Date,
              IsReply: staff.IsReply,
              ReplyMessage: staff.ReplyMessage,
              viewCV: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#cvModal"
                  // onClick={handleClick}
                  onClick={() => openResume(staff.Resume)}
                >
                  View CV
                </button>
              ),
              action: (
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ backgroundColor: "#008435" }}
                  data-toggle="modal"
                  data-target="#myModal"
                  onClick={() =>
                    setCreateItem({
                      ApplicationId: staff.ApplicationId,
                      FullName: staff.FullName,
                      EmailAddress: staff.EmailAddress,
                      YearsOfExperience: staff.YearsOfExperience,
                      PhoneNumber: staff.PhoneNumber,
                      Address: staff.Address,
                      HighestQualification: staff.HighestQualification,
                      FormerEmployee: staff.FormerEmployee,
                      PositionApplying: staff.PositionApplying,
                      Gender: staff.Gender,
                      Resume: staff.Resume,
                      ApplicantLocation: staff.ApplicantLocation,
                      IsSelected: staff.IsSelected,
                      CreatedDate: staff.CreatedDate,
                      // #
                      Message: staff.Message,
                      Date: staff.Date,
                      IsReply: staff.IsReply,
                      ReplyMessage: staff.ReplyMessage,
                    })
                  }
                >
                  Message Applicant
                </button>
              ),
            });
          });

          setDatatable({
            ...datatable,
            columns: datatable.columns,
            rows: rows,
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("NETWORK ERROR");
      });
  };

  const navigate = useNavigate();
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  useEffect(() => {
    getProjects().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
      if (currentUser.length === 0) {
        navigate("/auth");
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  const sendNotification = (email, subject, names, message, reply) => {
    fetch(`${serverLink}mail/send`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        subject: subject,
        names: names,
        message: message,
        reply: reply,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus("Success");
      })
      .catch((error) => {
        setStatus("Error");
        console.log(error);
      });
  };

  const openResume = (resumeUrl) => {
    setSelectedFile(resumeUrl);
  };

  const onSubmit = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "MessageId" &&
        key !== "Date" &&
        key !== "ReplyDate" &&
        key !== "IsReply"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    const sendData = {
      MessageId: createItem.MessageId,
      FullName: createItem.FullName,
      Subject: createItem.Subject,
      EmailAddress: createItem.EmailAddress,
      PhoneNumber: createItem.PhoneNumber,
      Message: createItem.Message,
      ReplyMessage: createItem.ReplyMessage,
      ReplyDate: `${currentMonth}/${currentDay}/${currentYear}`,
      IsReply: Number(1),
      Date: `${currentMonth}/${currentDay}/${currentYear}`,
    };

    if (sendData) {
      axios
        .patch(`${serverLink}message/update`, sendData)
        .then(async (res) => {
          if (res.data.message === "success") {
            resetItem();
            sendNotification(
              `${createItem.EmailAddress}`,
              `${createItem.Subject}`,
              `${createItem.FullName}`,
              `${createItem.Message}`,
              `${createItem.ReplyMessage}`
            );
            await showAlert(
              "Success",
              `Message replied successfully`,
              "success"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }
  };

  const LinkRenderer = ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const components = {
    a: LinkRenderer,
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage Applicants</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        {isSubmittingForm && <SubmittingLoader />}
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  Message Applicants
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      name="FullName"
                                      id="FullName"
                                      type="text"
                                      disabled={true}
                                      placeholder="Full Name"
                                      value={createItem.FullName}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      name="EmailAddress"
                                      id="EmailAddress"
                                      type="email"
                                      disabled={true}
                                      placeholder="Email Address"
                                      value={createItem.EmailAddress}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      name="PhoneNumber"
                                      id="PhoneNumber"
                                      type="text"
                                      disabled={true}
                                      placeholder="Phone Number"
                                      value={createItem.PhoneNumber}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      name="Subject"
                                      id="Subject"
                                      type="text"
                                      disabled={true}
                                      placeholder="Subject"
                                      value={createItem.Subject}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      id="Message"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Message"
                                      value={createItem.Message}
                                      onChange={onEdit}
                                      disabled={true}
                                      className="form-control message-box"
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    {" "}
                                    <textarea
                                      id="ReplyMessage"
                                      type="text"
                                      style={{
                                        height: "120px",
                                      }}
                                      placeholder="Reply Message"
                                      value={createItem.ReplyMessage}
                                      onChange={onEdit}
                                      className="form-control message-box"
                                      cols="30"
                                      rows="10"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>

                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Send message
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">List of Applicants</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>

                      <div className="panel-body">
                        <div
                          className="modal fade"
                          id="cvModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="cvModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body">
                                <>
                                  {selectedFile && (
                                    <>
                                      <img src={selectedFile} />
                                      {/*<ReactMarkdown components={components}>*/}
                                      {/*  {selectedFile}*/}
                                      {/*</ReactMarkdown>*/}
                                    </>
                                  )}
                                </>
                                {/*{showViewer && (*/}

                                {/*)}*/}
                              </div>

                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getProjects}
                                >
                                  Close
                                </button>

                                <button
                                  type="button"
                                  style={{ backgroundColor: "#008435" }}
                                  className="btn btn-primary"
                                  onClick={onSubmit}
                                >
                                  Send message
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {showViewer && <ReactMarkdown>{content}</ReactMarkdown>}
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Applicants);
