import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  encryptData,
  formatDate,
  formatDateAndTime,
  serverLink,
} from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import arrayShuffle from "array-shuffle";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import Table from "../pages/table/table";
import Header from "./Header";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";
import SubmittingLoader from "./SubmittingLoader/SubmittingLoader";

function AddNews(props) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const [selectedFile, setSelectedFile] = useState(null);
  const [position, setPosition] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const [station] = useState(
    typeof props?.HomePageData.stations !== "undefined"
      ? props?.HomePageData.stations
      : []
  );

  const [oap] = useState(
    typeof props?.HomePageData.oap !== "undefined"
      ? props?.HomePageData.oap
      : []
  );

  console.log("oap", oap);
  console.log("station", station);

  const [createItem, setCreateItem] = useState({
    NewsID: "",
    Title: "",
    ParagraphOne: "",
    ParagraphTwo: "",
    ParagraphThree: "",
    StaffId: "",
    CreatedDate: "",
    IsActive: "",
    StationId: "",
    Cover: "",
    NewImage: "",
  });

  const resetItem = () => {
    setCreateItem({
      ...createItem,
      NewsID: "",
      Title: "",
      ParagraphOne: "",
      ParagraphTwo: "",
      ParagraphThree: "",
      StaffId: "",
      CreatedDate: "",
      IsActive: "",
      StationId: "",
      Cover: "",
      NewImage: "",
    });
  };

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "S/N",
        field: "sn",
      },
      {
        label: "Title",
        field: "Title",
      },
      {
        label: "Staff",
        field: "StaffId",
      },
      {
        label: "Station",
        field: "StationId",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    rows: [],
  });

  const navigate = useNavigate();
  const [filteredOAP, setFilteredOAP] = useState([]);
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  const onEdit = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    if (e.target.type === "file") {
      if (id === "cover1") {
        setSelectedFile1(e.target.files[0]);
      } else if (id === "cover2") {
        setSelectedFile2(e.target.files[0]);
      }
    }

    if (id === "StationId") {
      if (value === "0") {
        // Handle the case when "All Stations" is selected
        // For example, set createItem.StationId to null or another value
        // to represent all stations.
        setCreateItem({
          ...createItem,
          [id]: value,
          StaffId: "", // Reset the StaffId to empty when the station is changed
        });
      } else {
        // Handle the case when a specific station is selected
        const selectedStationObject = station.find(
          (item) => item.StationId === Number(value)
        );

        if (selectedStationObject) {
          const filteredOAPs = oap.filter(
            (item) => item.StationId === Number(selectedStationObject.StationId)
          );
          setCreateItem({
            ...createItem,
            [id]: value,
            StaffId: "", // Reset the StaffId to empty when the station is changed
          });
          setFilteredOAP(filteredOAPs);
        }
      }
    } else {
      setCreateItem({
        ...createItem,
        [id]: value,
      });
    }
  };

  const getStaff = async () => {
    try {
      const result = await axios.get(`${serverLink}shared/get/news`);

      const rows = result.data.map((staff, index) => {
        const stationName = station.find(
          (x) => x.StationId === staff.StationId
        );

        const staffName = oap.find((x) => x.StationId === staff.StationId);
        return {
          sn: index + 1,
          NewsID: staff.NewsID,
          Title: staff.Title,
          ParagraphOne: staff.ParagraphOne,
          ParagraphTwo: staff.ParagraphTwo,
          ParagraphThree: staff.ParagraphThree,
          CreatedDate: staff.CreatedDate,
          StaffId: staffName ? staffName.FullName : "",
          IsActive: staff.IsActive,
          StationId: stationName ? stationName.StationName : "",
          Cover: staff.Cover,
          LongDescription: staff.LongDescription,
          NewImage: staff.NewImage,
          action: (
            <button
              type="button"
              className="btn btn-primary"
              style={{ backgroundColor: "#a7613b" }}
              data-toggle="modal"
              data-target="#myModal"
              onClick={() =>
                setCreateItem({
                  NewsID: staff.NewsID,
                  Title: staff.Title,
                  ParagraphOne: staff.ParagraphOne,
                  ParagraphTwo: staff.ParagraphTwo,
                  ParagraphThree: staff.ParagraphThree,
                  StaffId: staffName ? staffName.FullName : "",
                  CreatedDate: staff.CreatedDate,
                  IsActive: staff.IsActive,
                  StationId: stationName ? stationName.StationName : "",
                  Cover: staff.Cover,
                  LongDescription: staff.LongDescription,
                  NewImage: staff.NewImage,
                })
              }
            >
              Edit
            </button>
          ),
        };
      });

      setDatatable({
        ...datatable,
        columns: datatable.columns,
        rows: rows,
      });

      setIsLoading(false);
    } catch (err) {
      console.log("NETWORK ERROR", err);
    }
  };

  // const getStaff = async () => {
  //   await axios
  //     .get(`${serverLink}shared/news/list`)
  //     .then((result) => {
  //       console.log("result.data inside", result.data);
  //       if (result.data) {
  //         let rows = [];
  //         result.data.map((staff, index) => {
  //           const stationName = station.filter(
  //             (x) => x.StationId === staff.StationId
  //           )[0];
  //
  //           const staffName = oap.filter((x) => x.OAPId === staff.StaffId)[0];
  //
  //           rows.push({
  //             sn: index + 1,
  //             NewsID: staff.NewsID,
  //             Title: staff.Title,
  //             ParagraphOne: staff.ParagraphOne,
  //             ParagraphTwo: staff.ParagraphTwo,
  //             ParagraphThree: staff.ParagraphThree,
  //             StaffId: staffName.FullName,
  //             CreatedDate: staff.CreatedDate,
  //             IsActive: staff.CreatedDate,
  //             StationId: stationName.StationName,
  //             Cover: staff.Cover,
  //             LongDescription: staff.LongDescription,
  //             NewImage: staff.NewImage,
  //             action: (
  //               <button
  //                 type="button"
  //                 className="btn btn-primary"
  //                 style={{ backgroundColor: "#a7613b" }}
  //                 data-toggle="modal"
  //                 data-target="#myModal"
  //                 onClick={() =>
  //                   setCreateItem({
  //                     NewsID: staff.NewsID,
  //                     Title: staff.Title,
  //                     ParagraphOne: staff.ParagraphOne,
  //                     ParagraphTwo: staff.ParagraphTwo,
  //                     ParagraphThree: staff.ParagraphThree,
  //                     StaffId: staff.StaffId,
  //                     CreatedDate: staff.CreatedDate,
  //                     IsActive: staff.IsActive,
  //                     StationId: staff.StationId,
  //                     Cover: staff.Cover,
  //                     LongDescription: staff.LongDescription,
  //                     NewImage: staff.NewImage,
  //                   })
  //                 }
  //               >
  //                 Edit
  //               </button>
  //             ),
  //           });
  //         });
  //
  //         setDatatable({
  //           ...datatable,
  //           columns: datatable.columns,
  //           rows: rows,
  //         });
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log("NETWORK ERROR");
  //     });
  // };

  console.log("datatable", datatable);
  useEffect(() => {
    getStaff().then((r) => {});
    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);

  const uploadImage = async (formData, endpoint) => {
    try {
      const response = await axios.post(`${serverLink}${endpoint}`, formData);
      if (response.data.type === "success") {
        console.log("!!!!!", response.data.file.url);
        return response.data.file.url;
      }
      throw new Error("Image upload failed.");
    } catch (error) {
      console.error("Image upload error:", error);
      throw error;
    }
  };

  const onSubmitProgram = async (e) => {
    setIsSubmittingForm(true);
    e.preventDefault();
    for (let key in createItem) {
      if (
        createItem.hasOwnProperty(key) &&
        key !== "NewsID" &&
        key !== "CategoryID" &&
        key !== "CreatedDate" &&
        key !== "Cover" &&
        key !== "NewImage"
      ) {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          setIsSubmittingForm(false);
          return false;
        }
      }
    }

    if (
      createItem.Cover.size / 1024 > 2048 ||
      createItem.NewImage.size / 1024 > 2048
    ) {
      await showAlert("", `Image Size Can't be more than 2MB`, "error");
      setIsSubmittingForm(false);
      return false;
    }

    const formDataCover1 = new FormData();
    formDataCover1.append("cover1", selectedFile1);

    const formDataCover2 = new FormData();
    formDataCover2.append("cover2", selectedFile2);

    const cover1 =
      selectedFile1 &&
      selectedFile1.size > 0 &&
      (await uploadImage(formDataCover1, "news/upload/news/front"));

    const cover2 =
      selectedFile2 &&
      selectedFile2.size > 0 &&
      (await uploadImage(formDataCover2, "news/upload/news/back"));

    const sendData = {
      NewsID: createItem.NewsID,
      Title: createItem.Title,
      ParagraphOne: createItem.ParagraphOne,
      ParagraphTwo: createItem.ParagraphTwo,
      ParagraphThree: createItem.ParagraphThree,
      CategoryID: createItem.CategoryID,
      StaffId: createItem.StaffId,
      CreatedDate: `${currentMonth}/${currentDay}/${currentYear}`,
      IsActive: createItem.IsActive,
      StationId: createItem.StationId,
      Cover: cover1 ? cover1 : null,
      NewImage: cover2 ? cover2 : null,
    };

    if (createItem.NewsID === "") {
      await axios
        .post(`${serverLink}news/add`, sendData)
        .then(async (res) => {
          if (res.data.message === "success") {
            await showAlert("Success", `News Added Successfully`, "success");
            resetItem();
            await getStaff();
            setIsSubmittingForm(false);
          }
          if (res.data.message === "exist") {
            await showAlert(
              "Error",
              `News with given details already exist!`,
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }

    if (createItem.NewsID !== "") {
      await axios
        .patch(`${serverLink}news/update`, sendData)
        .then((result) => {
          if (result.data.message === "success") {
            showAlert("Success", "News Updated Successfully", "success");
            resetItem();
            getStaff();
            setIsSubmittingForm(false);
          } else {
            showAlert(
              "ERROR",
              "Something went wrong. Please try again!",
              "error"
            );
            setIsSubmittingForm(false);
          }
        })
        .catch((error) => {
          showAlert(
            "NETWORK ERROR",
            "Please check your connection and try again!",
            "error"
          );
          setIsSubmittingForm(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <div id="wrapper">
          <Header />
          <NavBar />
          <div id="page-wrapper">
            <div class="header">
              <h1 class="page-header">Manage News</h1>
            </div>
            <div id="page-inner">
              <div class="row">
                <div class="col-md-12">
                  <div class="panel panel-default">
                    <div class="panel-heading"></div>

                    <div class="panel-body">
                      <div className="panel-body">
                        <button
                          style={{
                            backgroundColor: "#a7613b",
                            float: "right",
                          }}
                          className="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          onClick={resetItem}
                        >
                          Add News
                        </button>
                        <div
                          className="modal fade"
                          id="myModal"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="myModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog">
                            <div
                              className="modal-content"
                              style={{
                                width: "700px",
                              }}
                            >
                              <div className="modal-header">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-hidden="true"
                                >
                                  &times;
                                </button>
                                <h4 className="modal-title" id="myModalLabel">
                                  News Component
                                </h4>
                              </div>
                              <div className="modal-body">
                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <input
                                      className="form-control"
                                      id="Title"
                                      type="text"
                                      placeholder="Title"
                                      value={createItem.Title}
                                      onChange={onEdit}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-control"
                                      id="StationId"
                                      name="StationId"
                                      onChange={onEdit}
                                      value={createItem.StationId}
                                    >
                                      <option value="">Select Station</option>
                                      <option value="0">All Stations</option>
                                      {station &&
                                        Object.keys(station).length > 0 &&
                                        station.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.StationId}
                                            >
                                              {item.StationName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <select
                                      disabled={false}
                                      className="form-control"
                                      id="StaffId"
                                      name="StaffId"
                                      onChange={onEdit}
                                      value={createItem.StaffId}
                                    >
                                      <option value="">Select Staff</option>
                                      {filteredOAP &&
                                        Object.keys(filteredOAP).length > 0 &&
                                        filteredOAP.map((item, index) => {
                                          return (
                                            <option
                                              key={index}
                                              value={item.OAPId}
                                            >
                                              {item.FullName}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphOne"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Paragraph One"
                                      value={createItem.ParagraphOne}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphTwo"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Paragraph Two"
                                      value={createItem.ParagraphTwo}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4">
                                  <div className="form-group">
                                    <textarea
                                      className="form-control"
                                      id="ParagraphThree"
                                      type="text"
                                      style={{
                                        height: "100px",
                                      }}
                                      placeholder="Paragraph Three"
                                      value={createItem.ParagraphThree}
                                      onChange={onEdit}
                                    ></textarea>
                                  </div>
                                </div>

                                <div className="col-lg-12 mb-4 ">
                                  <div className="form-group">
                                    <select
                                      id="IsActive"
                                      className="form-control"
                                      value={createItem.IsActive}
                                      onChange={onEdit}
                                    >
                                      <option value="">Select Status</option>
                                      <option value="1">Active</option>
                                      <option value="0">InActive</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="cover1">Front Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="cover1"
                                      name="cover1"
                                      placeholder="File"
                                      onChange={onEdit}
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>

                                <div className="col-lg-6 mb-4">
                                  <div className="form-group">
                                    <label htmlFor="cover2">News Image</label>
                                    <input
                                      type="file"
                                      accept=".jpg, .png"
                                      id="cover2"
                                      name="cover2"
                                      placeholder="File"
                                      onChange={onEdit}
                                    />
                                    <span className="alert-info">
                                      Only .png, .jpg
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="modal-footer">
                                <button
                                  type="button"
                                  className="btn btn-default"
                                  data-dismiss="modal"
                                  style={{
                                    backgroundColor: "#da3535",
                                    color: "white",
                                  }}
                                  onClick={getStaff}
                                >
                                  Close
                                </button>
                                <button
                                  type="button"
                                  style={{ backgroundColor: "#a7613b" }}
                                  className="btn btn-primary"
                                  onClick={onSubmitProgram}
                                >
                                  Save changes
                                </button>
                                {isSubmittingForm && <SubmittingLoader />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div class="panel panel-default">
                          <div class="panel-heading">News List</div>
                          <div className="card-body pt-0">
                            <Table data={datatable} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddNews);
