import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrayShuffle from "array-shuffle";
import { connect } from "react-redux";
import { currencyConverter, projectName } from "../resources/Url";
import { setLoginDetails } from "../actions/Actions";

const Header = (props) => {
  return (
    <>
      <div id="wrapper">
        <nav className="navbar navbar-default top-navbar" role="navigation">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target=".sidebar-collapse"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>
            <a class="navbar-brand" href="#">
              <strong>{projectName}</strong>
            </a>
          </div>

          <ul className="nav navbar-top-links navbar-right">
            <li className="dropdown">
              <a
                className="dropdown-toggle"
                data-toggle="dropdown"
                href="#"
                aria-expanded="false"
              >
                <i className="fa fa-fw"></i>{" "}
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
