import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import arrayShuffle from "array-shuffle";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";

function NavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const pathSegments = location.pathname
    .split("/")
    .filter((segment) => segment !== "");
  const firstItem = pathSegments[0];
  const [shouldNavigate, setShouldNavigate] = useState(false);

  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  const logout = () => {
    localStorage.removeItem("EnyimbaLoginHistory");
    navigate("/auth");
    window.location.reload();
  };

  useEffect(() => {
    if (!currentUser.isAuthenticated && !shouldNavigate) {
      navigate("/auth");
      setShouldNavigate(true);
    }
  }, [currentUser, shouldNavigate]);
  return (
    <>
      <div id="wrapper">
        <nav className="navbar-default navbar-side" role="navigation">
          <div id="sideNav" href="#">
            <i className="fa fa-caret-right"></i>
          </div>
          <div className="sidebar-collapse">
            {/*Administrator Permissions*/}
            {currentUser && currentUser.Permission === 1 && (
              <ul className="nav" id="main-menu">
                <li>
                  <Link
                    to="/home"
                    className={
                      firstItem && firstItem.includes("home")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staff"
                    className={
                      firstItem && firstItem.includes("staff")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-users"></i> Manage Staff
                  </Link>
                </li>
                <li>
                  <Link
                    to="/election"
                    className={
                      firstItem && firstItem.includes("election")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Election
                  </Link>
                </li>
                <li>
                  <Link
                    to="/program"
                    className={
                      firstItem && firstItem.includes("program")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Program
                  </Link>
                </li>

                <li>
                  <Link
                    to="/podcast"
                    className={
                      firstItem && firstItem.includes("podcast")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Podcast
                  </Link>
                </li>
                <li>
                  <Link
                    to="/report"
                    className={
                      firstItem && firstItem.includes("report")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-book"></i> Reports
                  </Link>
                </li>
                <li>
                  <Link
                    to="/applicants"
                    className={
                      firstItem && firstItem.includes("applicants")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Applicants
                  </Link>
                </li>
              </ul>
            )}

            {/*Manager Permissions*/}
            {currentUser && currentUser.Permission === 2 && (
              <ul className="nav" id="main-menu">
                <li>
                  <Link
                    to="/home"
                    className={
                      firstItem && firstItem.includes("home")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staff"
                    className={
                      firstItem && firstItem.includes("staff")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-users"></i> Manage Staff
                  </Link>
                </li>
                <li>
                  <Link
                    to="/program"
                    className={
                      firstItem && firstItem.includes("program")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Program
                  </Link>
                </li>
                <li>
                  <Link
                    to="/news"
                    className={
                      firstItem && firstItem.includes("news")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/oap"
                    className={
                      firstItem && firstItem.includes("oap")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage OAP
                  </Link>
                </li>
                <li>
                  <Link
                    to="/podcast"
                    className={
                      firstItem && firstItem.includes("podcast")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Podcast
                  </Link>
                </li>
                <li>
                  <Link
                    to="/applicants"
                    className={
                      firstItem && firstItem.includes("applicants")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Applicants
                  </Link>
                </li>
              </ul>
            )}

            {/*DOCTOR*/}
            {currentUser && currentUser.Permission === 5 && (
              <ul className="nav" id="main-menu">
                <li>
                  <Link
                    to="/applicants"
                    className={
                      firstItem && firstItem.includes("applicants")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Applicants
                  </Link>
                </li>
              </ul>
            )}

            {/*Sales Permissions*/}
            {currentUser && currentUser.Permission === 3 && (
              <ul className="nav" id="main-menu">
                <li>
                  <Link
                    to="/home"
                    className={
                      firstItem && firstItem.includes("home")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Dashboard
                  </Link>
                </li>
                <li>
                  <Link
                    to="/staff"
                    className={
                      firstItem && firstItem.includes("staff")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-users"></i> Manage Staff
                  </Link>
                </li>
                <li>
                  <Link
                    to="/program"
                    className={
                      firstItem && firstItem.includes("program")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Program
                  </Link>
                </li>
                <li>
                  <Link
                    to="/news"
                    className={
                      firstItem && firstItem.includes("news")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage News
                  </Link>
                </li>
                <li>
                  <Link
                    to="/oap"
                    className={
                      firstItem && firstItem.includes("oap")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage OAP
                  </Link>
                </li>

                <li>
                  <Link
                    to="/podcast"
                    className={
                      firstItem && firstItem.includes("podcast")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-bar-chart-o"></i> Manage Podcast
                  </Link>
                </li>
                <li>
                  <Link
                    to="/applicants"
                    className={
                      firstItem && firstItem.includes("applicants")
                        ? `active-menu`
                        : ""
                    }
                  >
                    <i className="fa fa-dashboard"></i> Applicants
                  </Link>
                </li>
              </ul>
            )}
            <ul className="nav" id="main-menu">
              <li>
                <Link
                  onClick={logout}
                  className={
                    firstItem && firstItem.includes("logout")
                      ? `active-menu`
                      : ""
                  }
                >
                  <i className="fa fa-user"></i> Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
