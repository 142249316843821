import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { currencyConverter, formatDate, serverLink } from "../resources/Url";
import { showAlert } from "../pages/sweetalert/sweetalert";
import axios from "axios";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import NavBar from "./NavBar";
import Footer from "./Footer";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import logo from "../resources/mibusiness-report.png";
import HomeContentLoader from "./HomeContentLoader/HomeContentLoader";

function ReportAndStatistic(props) {
  const [position, setPosition] = useState(0);
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [grandTotal, setGrandTotal] = useState([]);
  const [canPrint, setCanPrint] = useState(false);
  const [printableData, setPrintableData] = useState([]);
  const [createItem, setCreateItem] = useState({
    Date: "",
    ReportType: "",
    Mode: "",
    BusinessID: "",
  });

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // Note: months are zero-indexed
  const currentDay = currentDate.getDate();

  const resetForm = () => {
    setCreateItem({
      ...createItem,
      Date: "",
      ReportType: "",
      Mode: "",
      BusinessID: "",
    });
  };

  const navigate = useNavigate();
  const currentUser =
    JSON.parse(localStorage.getItem("EnyimbaLoginHistory")) || [];

  const [services] = useState(
    typeof props?.HomePageData.services !== "undefined"
      ? props?.HomePageData.services
      : []
  );

  const [business] = useState(
    typeof props?.HomePageData.business !== "undefined"
      ? props?.HomePageData.business
      : []
  );

  const onEdit = (e) => {
    setCreateItem({
      ...createItem,
      [e.target.id]: e.target.value,
    });

    setPrintableData([]);
    setCanPrint(false);
  };

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const generateReceipt = () => {
    printReceipt();
  };

  useEffect(() => {
    if (currentUser.length === 0) {
      navigate("/auth");
    }
    if (business) {
      const timeoutId = setTimeout(() => {
        setPosition(1);
        setIsLoading(false);
      }, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [currentUser]);

  useEffect(() => {
    window.onafterprint = () => {
      window.location.reload();
      setCanPrint(false);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    for (let key in createItem) {
      if (createItem.hasOwnProperty(key) && key !== "BusinessID") {
        if (createItem[key] === "") {
          await showAlert("EMPTY FIELD", `Please enter ${key}`, "error");
          return false;
        }
      }
    }
    if (createItem.ReportType === "Daily" && createItem.Mode === "Single") {
      await axios
        .get(`${serverLink}shared/get/sales`)
        .then((result) => {
          if (result.data.length > 0) {
            const selectedData = createItem.Date;
            const year = selectedData.split("-")[0];
            const month = selectedData.split("-")[1];
            const day = selectedData.split("-")[2];

            const searchableDateForm = `${month
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "")}/${day
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "")}/${year}`;

            const timeoutId = setTimeout(() => {
              setPosition(1);
              const dailyReportArray = result.data.filter(
                (item) =>
                  item.DateOfEntry === searchableDateForm.trim() &&
                  item.BusinessID === Number(createItem.BusinessID)
              );

              setPosition(1);
              setPrintableData(dailyReportArray);
              setGrandTotal(dailyReportArray.reduce((a, b) => a + b.Total, 0));
              setIsLoading(false);
              setCanPrint(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
          }
        })
        .catch((err) => {
          console.log("NETWORK ERROR", err);
        });
    }

    if (createItem.ReportType === "Monthly" && createItem.Mode === "Single") {
      await axios
        .get(`${serverLink}shared/get/sales`)
        .then((result) => {
          if (result.data.length > 0) {
            const selectedData = createItem.Date;
            const year = selectedData.split("-")[0];
            const month = selectedData
              .split("-")[1]
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "");
            const day = selectedData
              .split("-")[2]
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "");

            const timeoutId = setTimeout(() => {
              setPosition(1);
              const dailyReportArray = result.data.filter(
                (item) =>
                  item.Month === Number(month) &&
                  item.BusinessID === Number(createItem.BusinessID)
              );

              setPosition(1);
              setPrintableData(dailyReportArray);
              setGrandTotal(dailyReportArray.reduce((a, b) => a + b.Total, 0));
              setIsLoading(false);
              setCanPrint(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
          }
        })
        .catch((err) => {
          console.log("NETWORK ERROR", err);
        });
    }

    if (createItem.ReportType === "Daily" && createItem.Mode === "All") {
      await axios
        .get(`${serverLink}shared/get/sales`)
        .then((result) => {
          if (result.data.length > 0) {
            const selectedData = createItem.Date;
            const year = selectedData.split("-")[0];
            const month = selectedData.split("-")[1];
            const day = selectedData.split("-")[2];

            const searchableDateForm = `${month
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "")}/${day
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "")}/${year}`;

            const timeoutId = setTimeout(() => {
              setPosition(1);
              const dailyReportArray = result.data.filter(
                (item) => item.DateOfEntry === searchableDateForm.trim()
              );

              setPosition(1);
              setPrintableData(dailyReportArray);
              setGrandTotal(dailyReportArray.reduce((a, b) => a + b.Total, 0));
              setIsLoading(false);
              setCanPrint(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
          }
        })
        .catch((err) => {
          console.log("NETWORK ERROR", err);
        });
    }

    if (createItem.ReportType === "Monthly" && createItem.Mode === "All") {
      await axios
        .get(`${serverLink}shared/get/sales`)
        .then((result) => {
          if (result.data.length > 0) {
            const selectedData = createItem.Date;
            const year = selectedData.split("-")[0];
            const month = selectedData
              .split("-")[1]
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "");
            const day = selectedData
              .split("-")[2]
              .toString()
              .padStart(2, "0")
              .replace(/^0/, "");

            const timeoutId = setTimeout(() => {
              setPosition(1);
              const dailyReportArray = result.data.filter(
                (item) => item.Month === Number(month)
              );

              setPosition(1);
              setPrintableData(dailyReportArray);
              setGrandTotal(dailyReportArray.reduce((a, b) => a + b.Total, 0));
              setIsLoading(false);
              setCanPrint(true);
            }, 5000);

            return () => clearTimeout(timeoutId);
          }
        })
        .catch((err) => {
          console.log("NETWORK ERROR", err);
        });
    }

    const timeoutId = setTimeout(() => {
      setPosition(1);
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  };

  console.log("printableData", printableData);
  return (
    <>
      {isLoading ? (
        <HomeContentLoader />
      ) : (
        <>
          <div id="wrapper">
            <Header />
            <NavBar />
            <div id="page-wrapper">
              <div className="header">
                <h1 className="page-header">Report</h1>
              </div>
              <div id="page-inner">
                <div className="row">
                  <div className="col-md-12">
                    <div className="panel panel-default">
                      <div className="panel-heading"></div>

                      <div className="panel-body">
                        <div className="col-md-12">
                          <div className="modal-body">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <select
                                  id="ReportType"
                                  className="form-control"
                                  value={createItem.ReportType}
                                  onChange={onEdit}
                                >
                                  <option value="">Select Report</option>
                                  <option value="Daily">Daily</option>
                                  <option value="Monthly">Monthly</option>
                                  {/*<option value="Yearly">Yearly</option>*/}
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <select
                                  id="Mode"
                                  className="form-control"
                                  value={createItem.Mode}
                                  onChange={onEdit}
                                  disabled={
                                    createItem.ReportType === "" && true
                                  }
                                >
                                  <option value="">Select Mode</option>
                                  <option value="All">All Business</option>
                                  <option value="Single">Single</option>
                                </select>
                              </div>
                            </div>

                            {createItem.Mode === "Single" && (
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    id="BusinessID"
                                    name="BusinessID"
                                    onChange={onEdit}
                                    value={createItem.BusinessID}
                                    disabled={createItem.Mode === "" && true}
                                  >
                                    <option value="">Select Business</option>
                                    {business.length > 0 &&
                                      business.map((item, index) => {
                                        return (
                                          <option
                                            key={index}
                                            value={item.BusinessID}
                                          >
                                            {item.BusinessName}
                                          </option>
                                        );
                                      })}
                                  </select>
                                </div>
                              </div>
                            )}
                            {createItem &&
                              createItem.ReportType !== "" &&
                              createItem.Mode !== "" && (
                                <div
                                  className={
                                    createItem.Mode === "All"
                                      ? "col-lg-12"
                                      : "col-lg-6"
                                  }
                                >
                                  <div className="form-group">
                                    <input
                                      type="date"
                                      id="Date"
                                      className="form-control"
                                      placeholder="Date"
                                      value={formatDate(createItem.Date)}
                                      onChange={onEdit}
                                      disabled={
                                        createItem.BusinessID === "" &&
                                        createItem.Mode !== "All" &&
                                        true
                                      }
                                    />
                                  </div>
                                </div>
                              )}

                            <div className="col-lg-12">
                              {createItem.Date !== "" &&
                                createItem.Mode !== "" &&
                                createItem.ReportType !== "" &&
                                printableData &&
                                Object.keys(printableData).length === 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary col-lg-12"
                                    onClick={onSubmit}
                                  >
                                    Generate Report
                                  </button>
                                )}
                            </div>

                            <div className="col-lg-12">
                              {printableData &&
                                Object.keys(printableData).length > 0 && (
                                  <button
                                    type="button"
                                    className="btn btn-primary col-lg-12"
                                    onClick={generateReceipt}
                                  >
                                    Print Report
                                  </button>
                                )}
                            </div>

                            <div className="col-lg-12">
                              <br />
                              {canPrint &&
                              Object.keys(printableData).length > 0 ? (
                                <div
                                  ref={componentRef}
                                  style={{
                                    // display: "flex",
                                    alignItems: "center",
                                    marginLeft: "20px",
                                    marginRight: "20px",
                                    justifyContent: "center",
                                    width: "8.27in",
                                    height: "11.69in",
                                  }}
                                >
                                  <div>
                                    <div>
                                      <div>
                                        {/*Advanced Tables */}
                                        <div>
                                          <div
                                            className="panel"
                                            style={{
                                              backgroundColor: "#dedede",
                                            }}
                                          >
                                            <h4
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginBottom: "0",
                                              }}
                                            >
                                              <img
                                                src={logo}
                                                alt="Logo"
                                                style={{
                                                  height: "140px",
                                                  marginRight: "10px",
                                                  marginBottom: "0",
                                                }}
                                              />
                                            </h4>
                                            <h3
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginTop: "0",
                                              }}
                                            >
                                              {createItem.ReportType}{" "}
                                              Comprehensive Report for{" "}
                                              {createItem.Mode === "All"
                                                ? "All Business"
                                                : Object.keys(business).length >
                                                    0 &&
                                                  business
                                                    .filter(
                                                      (i) =>
                                                        i.BusinessID ===
                                                        Number(
                                                          createItem.BusinessID
                                                        )
                                                    )
                                                    .map((r) => r.BusinessName)}
                                            </h3>
                                            <h6
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              Generated By:{" "}
                                              {currentUser.FullName}
                                            </h6>
                                            <h6
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              Date:{" "}
                                              {`${currentMonth}/${currentDay}/${currentYear}`}
                                            </h6>
                                          </div>
                                          <div className="panel-body">
                                            <div className="table-responsive">
                                              <table
                                                className="table table-striped table-bordered table-hover"
                                                id="dataTables-example"
                                              >
                                                <thead>
                                                  <tr>
                                                    {/*<th>SalesID</th>*/}
                                                    <th>Service Description</th>
                                                    <th>Price</th>
                                                    <th>Quantity</th>
                                                    <th>Mode</th>
                                                    <th>Schedule Date</th>
                                                    <th>Total</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {printableData?.length > 0 &&
                                                    printableData.map(
                                                      (item, index) => {
                                                        return (
                                                          <tr
                                                            className="gradeC"
                                                            key={index}
                                                          >
                                                            <td>
                                                              {services.length >
                                                                0 &&
                                                                services
                                                                  .filter(
                                                                    (i) =>
                                                                      i.ServiceID ===
                                                                      Number(
                                                                        item.ServiceID
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (r) =>
                                                                      r.Title
                                                                  )}
                                                            </td>
                                                            <td>
                                                              {currencyConverter(
                                                                item.Price
                                                              )}
                                                            </td>
                                                            <td>
                                                              {item.Quantity}
                                                            </td>
                                                            <td>
                                                              {
                                                                item.DeliveryMode
                                                              }
                                                            </td>
                                                            <td>
                                                              {item.ScheduleDate
                                                                ? item.ScheduleDate
                                                                : "-"}
                                                            </td>
                                                            <td className="center">
                                                              {currencyConverter(
                                                                item.Total
                                                              )}
                                                            </td>
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                </tbody>
                                              </table>
                                              <p>
                                                <strong>Total Sales:</strong>{" "}
                                                {currencyConverter(grandTotal)}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        {/*End Advanced Tables*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  {canPrint && (
                                    <div className="panel-body">
                                      <div className="alert alert-info">
                                        <strong>Sorry!</strong> There is no data
                                        for the selected report.
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportAndStatistic);
