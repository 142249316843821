import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Authentication from "./Auhentication";
import { setLoginDetails } from "../actions/Actions";
import { connect } from "react-redux";
import AddStaff from "./AddStaff";
import ReportAndStatistic from "./ReportAndStatistic";
import AddElection from "./AddElection";
import Results from "./Results";
import AddProgram from "./AddProgram";
import AddNews from "./AddNews";
import AddOAP from "./OAP";
import AddPodcast from "./AddPodcast/AddPodcast";
import Applicants from "./Applicants/Applicants";

function Routing(props) {
  return (
    <>
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/auth" element={<Authentication />} />
        <Route path="/election" element={<AddElection />} />
        <Route path="/results" element={<Results />} />
        <Route path="/staff" element={<AddStaff />} />
        <Route path="/oap" element={<AddOAP />} />
        <Route path="/program" element={<AddProgram />} />
        <Route path="/news" element={<AddNews />} />
        <Route path="/report" element={<ReportAndStatistic />} />
        <Route path="/podcast" element={<AddPodcast />} />
        <Route path="/applicants" element={<Applicants />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
