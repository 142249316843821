import React from "react";
import { MDBDataTableV5 } from "mdbreact";

export default function Table(props) {
  return (
    <div
      className="table-responsive"
      style={{
        marginLeft: "20px",
        marginTop: "20px",
        marginRight: "10px",
      }}
    >
      <table className="table table-hover">
        <MDBDataTableV5
          hover
          entriesOptions={[50, 100, 200]}
          entries={50}
          theadColor={"purple"}
          theadTextWhite={true}
          pagesAmount={4}
          data={props.data}
          paging={props.paging ?? true}
          pagingTop
          searchTop
          searchBottom={false}
          barReverse
        />
      </table>
    </div>
  );
}
