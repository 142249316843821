import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setLoginDetails } from "../actions/Actions";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { serverLink } from "../resources/Url";

const Results = (props) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [processedVotes] = useState(
    typeof props.HomePageData.processedVotes !== "undefined"
      ? props.HomePageData.processedVotes
      : []
  );
  const [oap] = useState(
    typeof props.HomePageData.oap !== "undefined" ? props.HomePageData.oap : []
  );
  const [stations] = useState(
    typeof props.HomePageData.stations !== "undefined"
      ? props.HomePageData.stations
      : []
  );
  const [votes] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );
  const [questions] = useState(
    typeof props.HomePageData.questions !== "undefined"
      ? props.HomePageData.questions
      : []
  );

  const [pollResults] = useState(
    typeof props.HomePageData.votes !== "undefined"
      ? props.HomePageData.votes
      : []
  );

  const enyimbaFM = processedVotes.filter((x) => x.StationId === Number(1));
  const legendFM = processedVotes.filter((x) => x.StationId === Number(2));
  const roseFM = processedVotes.filter((x) => x.StationId === Number(3));
  const overallResults = processedVotes.sort(
    (a, b) => b.TotalVotes - a.TotalVotes
  );
  const [bestStaff, setBestStaff] = useState({});
  const [votesCount, setVotesCount] = useState({});
  const [enyimbaVotesCount, setEnyimbaVotesCount] = useState({});
  const [enyimbaBestStaff, setEnyimbaBestStaff] = useState({});
  const [roseVotesCount, setRoseVotesCount] = useState({});
  const [roseBestStaff, setRoseBestStaff] = useState({});
  const [legendVotesCount, setLegendVotesCount] = useState({});
  const [legendBestStaff, setLegendBestStaff] = useState({});
  const [showCountdown, setShowCountdown] = useState(true);
  const [countdown, setCountdown] = useState("");

  enyimbaFM.sort((a, b) => b.TotalVotes - a.TotalVotes);
  for (let i = 0; i < enyimbaFM.length && i < 3; i++) {
    enyimbaFM[i].Position = i + 1;
  }

  legendFM.sort((a, b) => b.TotalVotes - a.TotalVotes);
  for (let i = 0; i < legendFM.length && i < 3; i++) {
    legendFM[i].Position = i + 1;
  }

  roseFM.sort((a, b) => b.TotalVotes - a.TotalVotes);
  for (let i = 0; i < roseFM.length && i < 3; i++) {
    roseFM[i].Position = i + 1;
  }

  const topThreeStaff = overallResults.slice(0, 3);

  for (let i = 0; i < topThreeStaff.length; i++) {
    topThreeStaff[i].Position = i + 1;
  }

  const [election] = useState(
    typeof props.HomePageData.election !== "undefined"
      ? props.HomePageData.election
      : []
  );

  const convertToDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${month}/${day}/${year}`);
  };

  useEffect(() => {
    let countdownInterval;

    const currentDate = new Date();
    // const targetDate = new Date("July 20, 2023");
    const targetDate = convertToDate(election[0].StartDate);

    if (currentDate >= targetDate) {
      setShowCountdown(false);
    } else {
      countdownInterval = setInterval(() => {
        const timeRemaining = targetDate - new Date();
        const days = Math.floor(timeRemaining / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor(
          (timeRemaining % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

        setCountdown(
          `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`
        );

        if (timeRemaining <= 0) {
          clearInterval(countdownInterval);
          setShowCountdown(false);
        }
      }, 1000);
    }

    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const voteCount = {};
    votes.forEach((item) => {
      const { StaffId, QuestionId } = item;
      if (!voteCount[QuestionId]) {
        voteCount[QuestionId] = {};
      }
      if (!voteCount[QuestionId][StaffId]) {
        voteCount[QuestionId][StaffId] = 0;
      }
      voteCount[QuestionId][StaffId] += 1;
    });

    const bestStaffPerQuestion = {};
    for (const questionId in voteCount) {
      const staffVotes = voteCount[questionId];
      const bestStaffId = Object.keys(staffVotes).reduce((a, b) =>
        staffVotes[a] > staffVotes[b] ? a : b
      );
      bestStaffPerQuestion[questionId] = bestStaffId;
    }

    setBestStaff(bestStaffPerQuestion);
    setVotesCount(voteCount);
  }, [votes]);

  useEffect(() => {
    const enyimbaVotes = votes.filter((item) => item.StationId === Number(1));
    const voteCount = {};
    enyimbaVotes.forEach((item) => {
      const { StaffId, QuestionId } = item;
      if (!voteCount[QuestionId]) {
        voteCount[QuestionId] = {};
      }
      if (!voteCount[QuestionId][StaffId]) {
        voteCount[QuestionId][StaffId] = 0;
      }
      voteCount[QuestionId][StaffId] += 1;
    });

    const bestStaffPerQuestion = {};
    for (const questionId in voteCount) {
      const staffVotes = voteCount[questionId];
      const bestStaffId = Object.keys(staffVotes).reduce((a, b) =>
        staffVotes[a] > staffVotes[b] ? a : b
      );
      bestStaffPerQuestion[questionId] = bestStaffId;
    }

    setEnyimbaBestStaff(bestStaffPerQuestion);
    setEnyimbaVotesCount(voteCount);
  }, [votes]);

  useEffect(() => {
    const roseVotes = votes.filter((item) => item.StationId === Number(3));
    const voteCount = {};
    roseVotes.forEach((item) => {
      const { StaffId, QuestionId } = item;
      if (!voteCount[QuestionId]) {
        voteCount[QuestionId] = {};
      }
      if (!voteCount[QuestionId][StaffId]) {
        voteCount[QuestionId][StaffId] = 0;
      }
      voteCount[QuestionId][StaffId] += 1;
    });

    const bestStaffPerQuestion = {};
    for (const questionId in voteCount) {
      const staffVotes = voteCount[questionId];
      const bestStaffId = Object.keys(staffVotes).reduce((a, b) =>
        staffVotes[a] > staffVotes[b] ? a : b
      );
      bestStaffPerQuestion[questionId] = bestStaffId;
    }

    setRoseBestStaff(bestStaffPerQuestion);
    setRoseVotesCount(voteCount);
  }, [votes]);

  useEffect(() => {
    const legendVotes = votes.filter((item) => item.StationId === Number(2));
    const voteCount = {};
    legendVotes.forEach((item) => {
      const { StaffId, QuestionId } = item;
      if (!voteCount[QuestionId]) {
        voteCount[QuestionId] = {};
      }
      if (!voteCount[QuestionId][StaffId]) {
        voteCount[QuestionId][StaffId] = 0;
      }
      voteCount[QuestionId][StaffId] += 1;
    });

    const bestStaffPerQuestion = {};
    for (const questionId in voteCount) {
      const staffVotes = voteCount[questionId];
      const bestStaffId = Object.keys(staffVotes).reduce((a, b) =>
        staffVotes[a] > staffVotes[b] ? a : b
      );
      bestStaffPerQuestion[questionId] = bestStaffId;
    }

    setLegendBestStaff(bestStaffPerQuestion);
    setLegendVotesCount(voteCount);
  }, [votes]);

  /// -----------Start---------------- ///
  const calculateTotalVotes = (data) => {
    const staffVotes = {};

    for (const vote of data) {
      const staffId = vote.StaffId;
      const stationId = vote.StationId;
      const key = `${staffId}-${stationId}`;

      if (!staffVotes[key]) {
        staffVotes[key] = 0;
      }

      staffVotes[key]++;
    }

    const staffArray = Object.entries(staffVotes).map(([key, totalVotes]) => {
      const [staffId, stationId] = key.split("-");
      return {
        StaffId: parseInt(staffId),
        StationId: parseInt(stationId),
        TotalVotes: totalVotes,
      };
    });

    return staffArray;
  };

  const processDataAndInsertIntoTable = () => {
    const processedVotes = calculateTotalVotes(pollResults);

    // Call the endpoint to insert the processed data into the table
    axios
      .post(`${serverLink}vote/insertProcessedData`, processedVotes)
      .then((response) => {
        console.log("Data inserted successfully", response);
      })
      .catch((error) => {
        console.error("Error inserting data: ", error);
      });
  };

  useEffect(() => {
    processDataAndInsertIntoTable();
  }, []);
  /// --------------Ends------------------- ///

  const renderCountdown = () => {
    // return <div>Countdown: {countdown}</div>;
    return (
      <>
        <main
          style={{
            backgroundColor: "#8a583c",
          }}
        >
          <div className="container-fluid" style={{ marginTop: "30px" }}></div>
          <div className="wrapper_centering p-3">
            <div className="container_centering">
              <div className="container">
                <div className="row justify-content-between">
                  <div className="col-xl-6 col-lg-6 d-flex align-items-center">
                    <div className="main_title_1">
                      <h4
                        style={{
                          borderRadius: "3%",
                          overflow: "hidden",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "20px",
                          flexDirection: "column",
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                          color: "white",
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        <strong>Voting Guideline</strong>{" "}
                      </h4>

                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        To participate, use the candidate election panel where
                        the countdown is displayed: Click on your chosen
                        candidate for each question and proceed to the next.
                        Once you have answered all the questions, click on the{" "}
                        <strong>"submit"</strong> button to finalize your
                        voting.
                      </p>

                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        <a
                          style={{
                            color: "white",
                          }}
                          href="https://vote.enyinba.com/"
                          target="_blank"
                        >
                          Click here to vote
                        </a>{" "}
                      </p>
                      <p
                        style={{
                          color: "white",
                        }}
                      >
                        <em>- Signed Management -</em>
                      </p>
                    </div>
                  </div>
                  {/*VOTERS FORM START*/}
                  <div className="col-xl-5 col-lg-5">
                    <div
                      style={{
                        borderRadius: "3%",
                        overflow: "hidden",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "20px",
                        flexDirection: "column",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        fontSize: "20px",
                        textAlign: "center",
                        zIndex: "9999,",
                        height: "500px",
                      }}
                    >
                      {/*<strong></strong> <br />{" "}*/}
                      <div
                        style={{
                          backgroundColor: "rgba(213,24,24,0.5)",
                          borderRadius: "3%",
                        }}
                      >
                        <strong>
                          There is currently no timetable for voting
                        </strong>
                      </div>
                    </div>
                  </div>
                  {/*VOTERS FORM END*/}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  };

  return (
    <>
      {showCountdown ? (
        renderCountdown()
      ) : (
        <div id="wrapper">
          <Header />
          <div id="page-wrapper">
            <div
              style={{
                border: "1px solid #F9A825",
                borderRadius: "10px",
                boxShadow: "0 5px 5px 5px rgba(0, 0, 0, 0.8)",
                backgroundColor: "#F9A825",
              }}
            >
              <div
                className="header"
                style={{
                  textAlign: isMobile && "center",
                  backgroundColor: "#603017",
                }}
              >
                <h3
                  className="page-header"
                  style={{ textAlign: isMobile && "center" }}
                >
                  <br />
                  <strong>Poll Results Breakdown</strong>
                </h3>
              </div>
            </div>

            <div id="page-inner">
              <>
                <div className="row">
                  <div className="col-md-12"></div>
                </div>

                <div className="row">
                  {/*OVERALL RESULTS BREAKDOWN START*/}
                  <div
                    className="row"
                    style={{
                      border: "1px solid #F9A825",
                      borderRadius: "10px",
                      boxShadow: "0 5px 5px 5px rgba(0, 0, 0, 0.8)",
                      backgroundColor: "#F9A825",
                    }}
                  >
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        style={{
                          backgroundColor: "#603017",
                        }}
                      >
                        Overall Stations Result & Positions
                      </div>

                      <div className="panel-heading">OAPs Position</div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Names</th>
                                <th>Total Votes</th>
                                <th>Station</th>
                                <th>Position</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topThreeStaff.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {item.StaffId ? (
                                        <>
                                          {oap.length > 0 &&
                                            oap
                                              .filter(
                                                (i) =>
                                                  i.OAPId ===
                                                  Number(item.StaffId)
                                              )
                                              .map((x) => <>{x.FullName}</>)[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.TotalVotes ? item.TotalVotes : "-"}
                                    </td>
                                    <td>
                                      {item.StationId ? (
                                        <>
                                          {stations.length > 0 &&
                                            stations
                                              .filter(
                                                (i) =>
                                                  i.StationId ===
                                                  Number(item.StationId)
                                              )
                                              .map((x) => (
                                                <>{x.StationName}</>
                                              ))[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.Position
                                        ? `${item.Position}`
                                        : "N/A"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="panel-heading">
                        Best OAPs By Impacted Programme
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>OAP Name</th>
                                <th>Vote Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(bestStaff).map((questionId) => {
                                const staffId = bestStaff[questionId];
                                const totalVotes =
                                  votesCount[questionId][staffId] || 0;

                                return (
                                  <tr key={questionId}>
                                    <td>
                                      {questions.length > 0 &&
                                        questions
                                          .filter(
                                            (i) =>
                                              i.QuestionId ===
                                              Number(questionId)
                                          )
                                          .map((x) => <>{x.QuestionText}</>)[0]}
                                    </td>
                                    <td>
                                      {totalVotes > 0 ? (
                                        <>
                                          {oap.length > 0 &&
                                            oap
                                              .filter(
                                                (i) =>
                                                  i.OAPId === Number(staffId)
                                              )
                                              .map((x) => <>{x.FullName}</>)[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>{totalVotes > 0 ? totalVotes : "-"}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/*OVERALL RESULTS BREAKDOWN END*/}

                  {/*ENYIMBA RESULTS BREAKDOWN START*/}
                  <div
                    className="row"
                    style={{
                      border: "1px solid #F9A825",
                      borderRadius: "10px",
                      boxShadow: "0 5px 5px 5px rgba(0, 0, 0, 0.8)",
                      backgroundColor: "#F9A825",
                    }}
                  >
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        style={{
                          backgroundColor: "#603017",
                        }}
                      >
                        Enyimba FM Results & OAPs Position
                      </div>

                      <div className="panel-heading">OAPs Position</div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Names</th>
                                <th>Total Votes</th>
                                {/*<th>Position</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {enyimbaFM.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {item.StaffId ? (
                                        <>
                                          {oap.length > 0 &&
                                            oap
                                              .filter(
                                                (i) =>
                                                  i.OAPId ===
                                                  Number(item.StaffId)
                                              )
                                              .map((x) => <>{x.FullName}</>)[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.TotalVotes ? item.TotalVotes : "-"}
                                    </td>
                                    {/*<td>*/}
                                    {/*  {item.Position*/}
                                    {/*    ? `${item.Position}`*/}
                                    {/*    : "N/A"}*/}
                                    {/*</td>*/}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="panel-heading">
                        Best OAPs By Impacted Programme
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>OAP Name</th>
                                <th>Vote Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(enyimbaBestStaff).map(
                                (questionId) => {
                                  const staffId = enyimbaBestStaff[questionId];
                                  const enyimbaTotalVotes =
                                    enyimbaVotesCount[questionId][staffId] || 0;

                                  return (
                                    <tr key={questionId}>
                                      <td>
                                        {questions.length > 0 &&
                                          questions
                                            .filter(
                                              (i) =>
                                                i.QuestionId ===
                                                Number(questionId)
                                            )
                                            .map((x) => (
                                              <>{x.QuestionText}</>
                                            ))[0]}
                                      </td>
                                      <td>
                                        <>
                                          {enyimbaTotalVotes > 0 ? (
                                            <>
                                              {oap.length > 0 &&
                                                oap
                                                  .filter(
                                                    (i) =>
                                                      i.OAPId ===
                                                      Number(staffId)
                                                  )
                                                  .map((x) => (
                                                    <>{x.FullName}</>
                                                  ))[0]}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      </td>
                                      <td>
                                        {enyimbaTotalVotes > 0
                                          ? enyimbaTotalVotes
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/*ENYIMBA RESULTS BREAKDOWN END*/}

                  {/*ROSE RESULTS BREAKDOWN START*/}
                  <div
                    className="row"
                    style={{
                      border: "1px solid #F9A825",
                      borderRadius: "10px",
                      boxShadow: "0 5px 5px 5px rgba(0, 0, 0, 0.8)",
                      backgroundColor: "#F9A825",
                    }}
                  >
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        style={{
                          backgroundColor: "#603017",
                        }}
                      >
                        Rose Results & OAPs Position
                      </div>

                      <div className="panel-heading">OAPs Position</div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Names</th>
                                <th>Total Votes</th>
                                {/*<th>Position</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {roseFM.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {item.StaffId ? (
                                        <>
                                          {oap.length > 0 &&
                                            oap
                                              .filter(
                                                (i) =>
                                                  i.OAPId ===
                                                  Number(item.StaffId)
                                              )
                                              .map((x) => <>{x.FullName}</>)[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.TotalVotes ? item.TotalVotes : "-"}
                                    </td>
                                    {/*<td>*/}
                                    {/*  {item.Position*/}
                                    {/*    ? `${item.Position}`*/}
                                    {/*    : "N/A"}*/}
                                    {/*</td>*/}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="panel-heading">
                        Best OAPs By Impacted Programme
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>OAP Name</th>
                                <th>Vote Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(roseBestStaff).map((questionId) => {
                                const staffId = roseBestStaff[questionId];
                                const roseTotalVotes =
                                  roseVotesCount[questionId][staffId] || 0;

                                return (
                                  <tr key={questionId}>
                                    <td>
                                      {questions.length > 0 &&
                                        questions
                                          .filter(
                                            (i) =>
                                              i.QuestionId ===
                                              Number(questionId)
                                          )
                                          .map((x) => <>{x.QuestionText}</>)[0]}
                                    </td>
                                    <td>
                                      <>
                                        {roseTotalVotes > 0 ? (
                                          <>
                                            {oap.length > 0 &&
                                              oap
                                                .filter(
                                                  (i) =>
                                                    i.OAPId === Number(staffId)
                                                )
                                                .map((x) => (
                                                  <>{x.FullName}</>
                                                ))[0]}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </>
                                    </td>
                                    <td>
                                      {roseTotalVotes > 0
                                        ? roseTotalVotes
                                        : "-"}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/*ROSE RESULTS BREAKDOWN END*/}

                  {/*LEGEND RESULTS BREAKDOWN START*/}
                  <div
                    className="row"
                    style={{
                      border: "1px solid #F9A825",
                      borderRadius: "10px",
                      boxShadow: "0 5px 5px 5px rgba(0, 0, 0, 0.8)",
                      backgroundColor: "#F9A825",
                    }}
                  >
                    <div className="panel panel-default">
                      <div
                        className="panel-heading"
                        style={{
                          backgroundColor: "#603017",
                        }}
                      >
                        Legend Results & OAPs Position
                      </div>

                      <div className="panel-heading">OAPs Position</div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Names</th>
                                <th>Total Votes</th>
                                {/*<th>Position</th>*/}
                              </tr>
                            </thead>
                            <tbody>
                              {legendFM.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {item.StaffId ? (
                                        <>
                                          {oap.length > 0 &&
                                            oap
                                              .filter(
                                                (i) =>
                                                  i.OAPId ===
                                                  Number(item.StaffId)
                                              )
                                              .map((x) => <>{x.FullName}</>)[0]}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    <td>
                                      {item.TotalVotes ? item.TotalVotes : "-"}
                                    </td>
                                    {/*<td>*/}
                                    {/*  {item.Position*/}
                                    {/*    ? `${item.Position}`*/}
                                    {/*    : "N/A"}*/}
                                    {/*</td>*/}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="panel-heading">
                        Best OAPs By Impacted Programme
                      </div>
                      <div className="panel-body">
                        <div className="table-responsive">
                          <table className="table table-striped table-bordered table-hover">
                            <thead>
                              <tr>
                                <th>Question</th>
                                <th>OAP Name</th>
                                <th>Vote Count</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(legendBestStaff).map(
                                (questionId) => {
                                  const staffId = legendBestStaff[questionId];
                                  const legendTotalVotes =
                                    legendVotesCount[questionId][staffId] || 0;

                                  return (
                                    <tr key={questionId}>
                                      <td>
                                        {questions.length > 0 &&
                                          questions
                                            .filter(
                                              (i) =>
                                                i.QuestionId ===
                                                Number(questionId)
                                            )
                                            .map((x) => (
                                              <>{x.QuestionText}</>
                                            ))[0]}
                                      </td>
                                      <td>
                                        <>
                                          {legendTotalVotes > 0 ? (
                                            <>
                                              {oap.length > 0 &&
                                                oap
                                                  .filter(
                                                    (i) =>
                                                      i.OAPId ===
                                                      Number(staffId)
                                                  )
                                                  .map((x) => (
                                                    <>{x.FullName}</>
                                                  ))[0]}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      </td>
                                      <td>
                                        {legendTotalVotes > 0
                                          ? legendTotalVotes
                                          : "-"}
                                      </td>
                                    </tr>
                                  );
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {/*LEGEND RESULTS BREAKDOWN END*/}
                </div>
              </>

              <Footer />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    HomePageData: state.HomePageContents,
    UserProfile: state.LoginDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setOnLoginDetails: (p) => {
      dispatch(setLoginDetails(p));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
